import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function Contact() {
  const header_image = "/front/images/bg/5.jpg";
  const MySwal = withReactContent(Swal)


  var image = {
    background:
      "url('/front/images/others/contact-2.jpg') no-repeat scroll right center / cover",
  };
 // Base Url
 const url = process.env.REACT_APP_BASE_URL;
 // Base Url
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [setting, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/all_setting_data`)
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const submitForm = () => {
    //setIsLoadedSubmit(true);
    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("name", name);
    formData.append("message", message);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/submit_contact_us_data`, formData)
      .then((res) => {
        if (res.status === 200) 
        {
          setPhone("");
          setEmail("");
          setName("");
          setMessage("");
          MySwal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Message Submitted Successfully. We will contact you shortly.',
            showConfirmButton: false,
            timer: 3000
          })
        } 
        else 
        {
          MySwal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Message Submitted Failed. Please Try Again.',
            showConfirmButton: false,
            timer: 3000,
            width : '20rem',
          })
        }
      })
  };
  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <LoadingBar
          color='#f11946'
          progress={progress}
          height={3}
          onLoaderFinished={() => setProgress(0)}
        />
        <div
          className="breadcrumbs-area bread-bg-1 bg-opacity-black-70"
          style={{ backgroundImage: `url(${header_image})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">Contact</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Contact</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="contact-area pt-115 pb-115">
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-12">
                  <div className="get-in-toch">
                    <div className="section-title mb-30">
                      <h3>GET IN</h3>
                      <h2>TOUCH</h2>
                    </div>
                    
                    <ul className="contact-address">
                      <li>
                        <div className="contact-address-icon">
                          <img
                            src="/front/images/icons/location-2.png"
                            alt=""
                          />
                        </div>
                        <div className="contact-address-info">
                          <span>{setting.address}</span>
                        </div>
                      </li>
                      <li>
                        <div className="contact-address-icon">
                          <img src="/front/images/icons/phone-3.png" alt="" />
                        </div>
                        <div className="contact-address-info">
                          <span>Telephone : {setting.mobile}</span>
                        </div>
                      </li>
                      <li>
                        <div className="contact-address-icon">
                          <img src="/front/images/icons/world.png" alt="" />
                        </div>
                        <div className="contact-address-info">
                          <span>Email : {setting.email}</span>
                        </div>
                      </li>
                      <li>
                      <iframe src={setting.map} style={{width: '100%'}} allowFullScreen="" loading="lazy"></iframe>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-7 col-12">
                  <div className="contact-messge contact-bg" style={image}>
                    <div className="leave-review">
                      <h5>Leave a Message</h5>
                      <form>
                        <input
                          type="text"
                          name="name"
                          placeholder="Your name"
                          value={name}
                          onChange={(e) =>
                            setName(e.target.value)
                          }
                        />
                        <input 
                          type="email" 
                          name="email" 
                          placeholder="Email" 
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value)
                          }
                        />
                        <input 
                          type="text" 
                          name="phone" 
                          placeholder="phone" 
                          value={phone}
                          onChange={(e) =>
                            setPhone(e.target.value)
                          }
                        />
                        <textarea
                          name="message"
                          placeholder="Write here"
                          value={message}
                          onChange={(e) =>
                            setMessage(e.target.value)
                          }
                        ></textarea>
                        <button type="button" className="submit-btn-1" onClick={submitForm}>
                          SUBMIT
                        </button>
                      </form>
                      <p className="form-messege mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
