import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function AboutUs() {
  const url = process.env.REACT_APP_BASE_URL;

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [about, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/about_us_page`)
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  var service_carousel = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
  var agents_carousel = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
  var testimonial_carousel = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <LoadingBar
          color="#f11946"
          progress={progress}
          height={3}
          onLoaderFinished={() => setProgress(0)}
        />
        <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">About</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>About</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="about-sheltek-area ptb-55">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12 order-md-2">
                  <div className="section-title mb-30">
                    <h3>{about.about_us.heading}</h3>
                    <h2>{about.about_us.sub_heading}</h2>
                  </div>
                  <div className="about-sheltek-info">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: about.about_us.content,
                          }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 order-md-1">
                  <div className="about-image">
                    <img src={url+about.about_us.image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-area pb-60">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{about.section_title.our_service_title}</h2>
                    <p>{about.section_title.our_service_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Slider {...service_carousel}>
                    {about.all_sevice.map((sevice, i) => (
                      <div className="col" key={sevice.sevice_id+i}>
                        <div className="service-item">
                          <div className="service-item-image">
                            <Link to={"/services/" + sevice.service_slug}>
                              <img
                                src={url + sevice.service_image}
                                alt={sevice.service_title}
                              />
                            </Link>
                          </div>
                          <div className="service-item-info">
                            <h5>
                              <Link to={"/services/" + sevice.service_slug}>
                                {sevice.service_title}
                              </Link>
                            </h5>
                            <p>
                              {`${sevice.service_short_description.substring(
                                0,
                                100
                              )}...`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-area bg-1 call-to-bg plr-140 pt-75" style={{ backgroundImage: `url(${url + about.booking_section.bg_image})` }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>{about.booking_section.one_st_title}</h3>
                    <h2 className="h1">{about.booking_section.one_st_sub_title}</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="booking-conternt clearfix">
                    <div className="book-house text-white">
                      <h2>{about.booking_section.two_nd_title}</h2>
                      <h2 className="h5">{about.booking_section.two_nd_sub_title}</h2>
                    </div>
                    <div className="booking-imgae">
                      <img src={url + about.booking_section.image} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-agents-area pt-115 pb-55">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{about.section_title.agent_title}</h2>
                    <p>{about.section_title.agent_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="our-agents">
                <div className="row">
                  <div className="col-12">
                    <Slider {...agents_carousel}>
                      {about.all_agent.map((agent, i) => (
                        <div className="col-12" key={agent.id+i}>
                          <div className="single-agent">
                            <div className="agent-image">
                              <img src={url+agent.image} alt={agent.name} />
                            </div>
                            <div className="agent-info">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/excutive-details/'+agent.slug}>
                                  {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                            </div>
                            <div className="agent-info-hover">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/excutive-details/'+agent.slug}>
                                    {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                              <ul className="agent-address">
                                <li>
                                  <img
                                    src="/front/images/icons/phone-2.png"
                                    alt=""
                                  />{agent.mobile}
                                </li>
                                <li>
                                  <img
                                    src="/front/images/icons/mail-close.png"
                                    alt=""
                                  />{agent.email}
                                </li>
                              </ul>
                              <ul className="social-media">
                                <li>
                                  <a href={agent.fb_url}>
                                    <i
                                      className="fa fa-facebook"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.tw_url}>
                                    <i
                                      className="fa fa-twitter"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.linkdin_url}>
                                    <i
                                      className="fa fa-linkedin"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.telegram_url}>
                                    <i
                                      className="fa fa-telegram"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-area pb-115">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="testimonial">
                    <div className="row">
                      <div className="col-lg-8 col-md-9">
                        <div className="section-title mb-30">
                          <h3>{about.all_testimonial.th_heading}</h3>
                          <h2 className="h1">{about.all_testimonial.th_sub_heading}</h2>
                        </div>
                        <Slider {...testimonial_carousel}>
                          {about.all_testimonial.testimonial.map((testi, i) => (
                            <div className="testimonial-item">
                              <div className="testimonial-brief">
                                <p>{testi.content}</p>
                              </div>
                              <h6>
                              {testi.name}, <span>{testi.position}</span>
                              </h6>
                            </div>
                          ))}
                        </Slider>
                      </div>
                      <div className="col-lg-4 col-md-3">
                        <div className="testimonial-image">
                          <img
                            src={url+about.all_testimonial.th_image}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
