import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { useLocation, Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import axios from "axios";
import Moment from "react-moment";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
Moment.globalFormat = "D MMM YYYY hh:mm A";

export default function Search() {
  const url = process.env.REACT_APP_BASE_URL;
  const search = useLocation().search;
  const landmark = new URLSearchParams(search).get("landmark");
  const min = new URLSearchParams(search).get("min");
  const max = new URLSearchParams(search).get("max");
  const type = new URLSearchParams(search).get("type");
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [property, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/get_all_property_by_serach?landmark=` +
            landmark +
            `&min=` +
            min +
            `&max=` +
            max +
            `&type=` +
            type
        )
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  console.log(property);
  // Property API

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <LoadingBar
          color="#f11946"
          progress={progress}
          height={3}
          onLoaderFinished={() => setProgress(0)}
        />
        <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">Properties</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Properties</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="featured-flat-area pt-115 pb-60">
            <div className="container">
              <div className="row">
                {property.map((properties, i) => (
                  <div className="col-md-4 col-12">
                    <div className="flat-item">
                      <div className="flat-item-image">
                        {properties.pr_sale_or_rent == 1 ? (
                          <span className="for-sale">For Sale</span>
                        ) : properties.pr_sale_or_rent == 2 ? (
                          <span className="for-sale rent">For Rent</span>
                        ) : (
                          <span className="for-sale rent">For Long Lease</span>
                        )}
                        <Link to={"/propertie-details/" + properties.pr_slug}>
                          <Carousel
                            showArrows={false}
                            showIndicators={false}
                            swipeable={true}
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            infiniteLoop={true}
                          >
                            {properties.image.map((all_img, i) => (
                              <div key={"123" + i}>
                                <img src={url + all_img.pr_img} />
                              </div>
                            ))}
                          </Carousel>
                        </Link>
                        <div className="flat-link">
                          <Link to={"/propertie-details/" + properties.pr_slug}>
                            More Details
                          </Link>
                        </div>
                        <ul className="flat-desc">
                          {properties.area ? (
                            <li>
                              <img
                                src="/front/images/icons/4.png"
                                alt={properties.area}
                              />
                              <span>{properties.area}</span>
                            </li>
                          ) : (
                            <li></li>
                          )}
                          {properties.bedroom > 0 ? (
                            <li>
                              <img
                                src="/front/images/icons/5.png"
                                alt="Bedroom"
                              />
                              <span>{properties.bedroom}</span>
                            </li>
                          ) : (
                            <li></li>
                          )}
                          {properties.bathroom > 0 ? (
                            <li>
                              <img
                                src="/front/images/icons/6.png"
                                alt="BathRoom"
                              />
                              <span>{properties.bathroom}</span>
                            </li>
                          ) : (
                            <li></li>
                          )}
                        </ul>
                      </div>
                      <div className="flat-item-info">
                        <div className="flat-title-price">
                          <h5>
                            <Link
                              to={"/propertie-details/" + properties.pr_slug}
                            >
                              {properties.pr_name}
                            </Link>
                          </h5>
                          <span className="price">
                            {numberFormat(properties.price)}
                          </span>
                        </div>
                        <p>
                          <img src="/front/images/icons/location.png" alt="" />
                          {properties.pr_address}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
