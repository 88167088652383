import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import axios from "axios";
import PreLoader from "./PreLoader";
import { Helmet } from "react-helmet";

export default function Layout() {
  function openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }
  function handleChange() {
    closeNav();
  }
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  // Base Url
  const url = process.env.REACT_APP_BASE_URL;
  // Base Url

  // Setting API
  const [isLoading, setLoading] = useState(true);
  const [setting, setItemsHome] = useState([]);
  useEffect(() => {
    const getItemshome = async () => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/all_setting_data`)
        .then(function (resultHome) {
          setItemsHome(resultHome.data);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  // Setting API

  // Sticky Bar
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  let isSticky = ["header-middle-area transparent-header d-none d-md-block"];
  var isStickyMobile = ["mobile-menu-area d-block d-md-none"];
  if (scroll) {
    isSticky.push("sticky");
    var isStickyMobile = ["mobile-menu-area d-block d-md-none stickymobile"];
  } else {
    isSticky.push("");
    var isStickyMobile = ["mobile-menu-area d-block d-md-none"];
  }
  // Sticky Bar

  // Heading & Color Title
  document.querySelector("link[rel*='icon']").href = url + setting.fevicon;
  document.querySelector("link[rel*='apple-touch-icon']").href =
    url + setting.fevicon;
  document.documentElement.style.setProperty("--color_1", setting.color_1);
  document.documentElement.style.setProperty("--color_2", setting.color_2);
  document.documentElement.style.setProperty("--color_3", setting.color_1_rgb);
  document.documentElement.style.setProperty("--color_4", setting.color_2_rgb);
  // Heading Title

  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <Helmet>
          <meta charset="utf-8" />
          <title>{setting.application_name}</title>
          <meta name="description" content={setting.meta_description} />
          <meta name="keywords" content={setting.meta_keywords} />
        </Helmet>

        <div id="mySidenav" className="sidenav d-lg-none">
          <a href="#" className="closebtn" onClick={closeNav}>
            &times;
          </a>
          <ul className="navbar-nav">
            <li className="nav-item active" >
              <Link className="nav-link" to="/" onClick={handleChange}>
                Home
              </Link>
            </li>
            <li className="nav-item dropdown d-none">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                About Us
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li className="text-center">
                  <Link
                    onClick={handleChange}
                    className="dropdown-item"
                    to="/sale"
                  >
                    Our Excutive
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us" onClick={handleChange}>
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" onClick={handleChange}>
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/properties" onClick={handleChange}>
              Properties
              </Link>
            </li>
            
            <li className="nav-item d-none">
              <Link className="nav-link" to="/ask-expert" onClick={handleChange}>
                Ask Expert
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/our-excutive" onClick={handleChange}>
              Our Excutive
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog" onClick={handleChange}>
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={handleChange}>
                Contact
              </Link>
            </li>
          </ul>
        </div>

        <div className="wrapper">
          <header className="header-area header-wrapper">
            <div className="header-top-bar bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center">
                    <div className="logo">
                      <Link to="/">
                        <img
                          src={url + setting.header_logo}
                          alt={setting.application_name}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-9 d-none d-lg-block">
                    <div className="company-info clearfix">
                      {setting.mobile ? (
                        <div className="company-info-item">
                          <div className="header-icon">
                            <img src="/front/images/icons/phone.png" alt="" />
                          </div>
                          <div className="header-info">
                            <h6>{setting.mobile}</h6>
                            {setting.time ? <p>{setting.time}</p> : <p></p>}
                          </div>
                          <div></div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {setting.email ? (
                        <div className="company-info-item">
                          <div className="header-icon">
                            <img
                              src="/front/images/icons/mail-open.png"
                              alt=""
                            />
                          </div>
                          <div className="header-info">
                            <h6>
                              <Link to="mailto:info@domain.com">
                                {setting.email}
                              </Link>
                            </h6>
                            <p>You can mail us</p>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="sticky-header" className={isSticky.join(" ")}>
              <div className="container">
                <div className="full-width-mega-drop-menu">
                  <div className="row">
                    <div className="col-12">
                      <div className="sticky-logo">
                        <Link to="/">
                          <img  src={url + setting.header_logo}
                          alt={setting.application_name} />
                        </Link>
                      </div>
                      <nav id="primary-menu">
                        <ul className="main-menu text-center" style={{marginBottom: '0'}}>
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li className="d-none">
                            <Link to="#">About Us</Link>
                            <ul className="drop-menu">
                              <li>
                                <Link to="properties.html">Our Excutive</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/about-us">About Us</Link>
                          </li>
                          <li>
                            <Link to="/services">Services</Link>
                          </li>
                          <li>
                            <Link to="/properties">Properties</Link>
                          </li>
                          <li className="d-none">
                            <Link to="/ask-expert">Ask Expert</Link>
                          </li>
                          <li>
                            <Link to="/our-excutive">Our Excutive</Link>
                          </li>
                          <li>
                            <Link to="/blog">Blog</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className={isStickyMobile}>
            <div className="container">
              <div className="row">
                <div className="col-12" style={{ height: "50px" }}>
                  <div className="mobile-menu">
                    <nav id="dropdown">
                      <ul>
                        <li
                          onClick={openNav}
                          className="float-right"
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Outlet />
          <footer id="footer" className="footer-area bg-2 bg-opacity-black-90">
            <div className="footer-top pt-110 pb-80">
              <div className="container">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12 order-1">
                    <div className="footer-widget">
                      <div className="pb-4">
                        <img
                          src={url + setting.footer_logo}
                          alt={setting.application_name}
                        />
                      </div>
                      <h6 className="footer-titel">GET IN TOUCH</h6>
                      <ul className="footer-address">
                        {setting.address ? (
                          <li>
                            <div className="address-icon">
                              <img
                                src="/front/images/icons/location-2.png"
                                alt=""
                              />
                            </div>
                            <div className="address-info">
                              <span>{setting.address}</span>
                            </div>
                          </li>
                        ) : (
                          <li></li>
                        )}
                        {setting.mobile ? (
                          <li>
                            <div className="address-icon">
                              <img
                                src="/front/images/icons/phone-3.png"
                                alt=""
                              />
                            </div>
                            <div className="address-info">
                              <span>Telephone : {setting.mobile}</span>
                            </div>
                          </li>
                        ) : (
                          <li></li>
                        )}
                        {setting.email ? (
                          <li>
                            <div className="address-icon">
                              <img src="/front/images/icons/world.png" alt="" />
                            </div>
                            <div className="address-info">
                              <span>Email : {setting.email}</span>
                            </div>
                          </li>
                        ) : (
                          <li></li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-5 col-12 order-3 order-lg-2 mt-md-30">
                    <div className="footer-widget middle">
                      <h6 className="footer-titel">LATEST NEWS</h6>
                      <ul className="footer-latest-news">
                        <li>
                          <div className="latest-news-image">
                            <Link to="single-blog.html">
                              <img src="/front/images/blog/1.jpg" alt="" />
                            </Link>
                          </div>
                          <div className="latest-news-info">
                            <h6>
                              <Link to="single-blog.html">Beautiful Home</Link>
                            </h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur acinglit
                              sed do eiusmod tempor inciidunt ut labore{" "}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="latest-news-image">
                            <Link to="single-blog.html">
                              <img src="/front/images/blog/2.jpg" alt="" />
                            </Link>
                          </div>
                          <div className="latest-news-info">
                            <h6>
                              <Link to="single-blog.html">Beautiful Home</Link>
                            </h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur acinglit
                              sed do eiusmod tempor inciidunt ut labore{" "}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="latest-news-image">
                            <Link to="single-blog.html">
                              <img src="/front/images/blog/3.jpg" alt="" />
                            </Link>
                          </div>
                          <div className="latest-news-info">
                            <h6>
                              <Link to="single-blog.html">Beautiful Home</Link>
                            </h6>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur acinglit
                              sed do eiusmod tempor inciidunt ut labore{" "}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 order-2 order-lg-3 mt-sm-30">
                    <div className="footer-widget">
                      <h6 className="footer-titel">QUICK CONTACT</h6>
                      <div className="footer-contact">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur acinglit sed
                          do eiusmod tempor
                        </p>
                        <form
                          id="contact-form-2"
                          action="https://demo.hasthemes.com/sheltek-preview/sheltek/mail_footer.php"
                          method="post"
                        >
                          <input
                            type="email"
                            name="email2"
                            placeholder="Type your E-mail address..."
                          />
                          <textarea
                            name="message2"
                            placeholder="Write here..."
                          ></textarea>
                          <button type="submit" value="send">
                            Send
                          </button>
                        </form>
                        <p className="form-messege"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {setting.copywrite ? (
              <div className="footer-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="copyright text-center">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: setting.copywrite,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </footer>
        </div>
      </>
    );
  }
}
