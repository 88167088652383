import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function PropertiesDetails() {
  const url = process.env.REACT_APP_BASE_URL;

  const { slug } = useParams();

  // Agent API
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [propertie, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/get_property_by_slug/${slug}`
        )
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  // Agent API
  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
        <LoadingBar
          color="#f11946"
          progress={progress}
          height={3}
          onLoaderFinished={() => setProgress(0)}
        />
        <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">{propertie.pr_name}</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <Link to="index.html">Home</Link>
                    </li>
                    <li>
                      <Link to="/properties">Properties</Link>
                    </li>
                    <li>{propertie.pr_name}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="properties-details-area pt-55 pb-40">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="pro-details-image mb-30">
                    <Carousel
                      showArrows={false}
                      showIndicators={false}
                      swipeable={true}
                      thumbWidth={100}
                    >
                      {propertie.images.map((img, i) => (
                        <div key={"sss" + i}>
                          <img src={url + img.pr_img} alt={propertie.pr_name} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className="pro-details-short-info mb-40">
                    <div className="row">
                      <div className="col-sm-12 col-xs-12 pb-lg-3">
                        <div className="pro-details-condition">
                          <h5>Condition</h5>
                          <div className="pro-details-condition-inner bg-gray">
                            <div className="condition-list row">
                              <div className="col-sm-6 col-6">
                                <img src="/front/images/icons/4.png" alt="" />
                                Condition: {propertie.pr_condition}
                              </div>
                              <div className="col-sm-6 col-6">
                                <img src="/front/images/icons/10.png" alt="" />
                                Mode: {propertie.pr_mode}
                              </div>
                              <div className="col-sm-6 col-6">
                                <img src="/front/images/icons/18.png" alt="" />
                                Sanction By: {propertie.pr_sanction}
                              </div>
                              {propertie.area ? (
                                <div className="col-sm-6 col-6">
                                  <img src="/front/images/icons/5.png" alt="" />
                                  Area: {propertie.area}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              {propertie.bedroom > 0 ? (
                                <div className="col-sm-6 col-6">
                                  <img src="/front/images/icons/6.png" alt="" />
                                  Bedroom: {propertie.bedroom}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              {propertie.bathroom > 0 ? (
                                <div className="col-sm-6 col-6">
                                  <img src="/front/images/icons/7.png" alt="" />
                                  Bathroom: {propertie.bathroom}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              {propertie.garage > 0 ? (
                                <div className="col-sm-6 col-6">
                                  <img
                                    src="/front/images/icons/13.png"
                                    alt=""
                                  />
                                  Garage: {propertie.garage}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              {propertie.kitchen > 0 ? (
                                <div className="col-sm-6 col-6">
                                  <img
                                    src="/front/images/icons/14.png"
                                    alt=""
                                  />
                                  Kitchen: {propertie.kitchen}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <div className="col-sm-6 col-6">
                                <img src="/front/images/icons/19.png" alt="" />
                                {numberFormat(propertie.price)}
                              </div>
                              <div className="col-sm-6 col-6">
                                <img src="/front/images/icons/25.png" alt="" />
                                Type: {propertie.pr_avl_type}
                              </div>
                            </div>
                            <p>
                              <img
                                src="/front/images/icons/location.png"
                                alt=""
                              />
                              {propertie.pr_address}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-xs-12 pb-lg-4 pt-lg-2">
                        <div className="pro-details-amenities">
                          <h5>Indoor Amenities</h5>
                          <div className="pro-details-amenities-inner bg-gray">
                            <div className="row amenities-list">
                              {JSON.parse(propertie.pr_amenities).map(
                                (amenities, i) => (
                                  <div
                                    className="col-sm-6 col-12"
                                    key={amenities + i}
                                  >
                                    {amenities}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-xs-12">
                        <div className="pro-details-amenities">
                          <h5>Outdoor Amenities</h5>
                          <div className="pro-details-amenities-inner bg-gray">
                            <div className="row amenities-list">
                              {JSON.parse(propertie.pr_out_door_amenities).map(
                                (amenities, i) => (
                                  <div
                                    className="col-sm-6 col-12"
                                    key={amenities + i}
                                  >
                                    {amenities}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-xs-12 pt-lg-4">
                        <div className="pro-details-amenities">
                        <h5>Location Advantage</h5>
                          <div className="pro-details-amenities-inner">
                            <div className="row ">
                              <div className="col-sm-6">
                                <img src={url + propertie.pr_sec_img} />
                              </div>
                              <div className="col-sm-6">
                                <div className="row amenities-list">
                                  {JSON.parse(propertie.pr_sec_contant).map(
                                    (contant, i) => (
                                      <div
                                        className="col-sm-12 col-12"
                                        key={contant + i}
                                      >
                                        {contant}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pro-details-description mb-50">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: propertie.pr_description,
                      }}
                    />
                  </div>

                  <div className="pro-details-agent-review">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-12">
                        <div className="pro-details-agent">
                          <h5>Contact Excutive</h5>

                          <div className="single-agent">
                            <div className="agent-image">
                              <img
                                src={url + propertie.image}
                                alt={propertie.name}
                              />
                            </div>
                            <div className="agent-info">
                              <div className="agent-name">
                                <h5>
                                  <Link
                                    to={"/excutive-details/" + propertie.slug}
                                  >
                                    {propertie.name}
                                  </Link>
                                </h5>
                                <p>{propertie.position}</p>
                              </div>
                            </div>
                            <div className="agent-info-hover">
                              <div className="agent-name">
                                <h5>
                                  <Link
                                    to={"/excutive-details/" + propertie.slug}
                                  >
                                    {propertie.name}
                                  </Link>
                                </h5>
                                <p>{propertie.position}</p>
                              </div>
                              <ul className="agent-address">
                                <li>
                                  <img
                                    src="/front/images/icons/phone-2.png"
                                    alt=""
                                  />
                                  {propertie.mobile}
                                </li>
                                <li>
                                  <img
                                    src="/front/images/icons/mail-close.png"
                                    alt=""
                                  />
                                  {propertie.email}
                                </li>
                              </ul>
                              <ul className="social-media">
                                <li>
                                  <a href={propertie.fb_url}>
                                    <i
                                      className="fa fa-facebook"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={propertie.tw_url}>
                                    <i
                                      className="fa fa-twitter"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={propertie.linkdin_url}>
                                    <i
                                      className="fa fa-linkedin"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={propertie.telegram_url}>
                                    <i
                                      className="fa fa-telegram"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-12">
                        <div className="leave-review">
                          <h5>Leave a Review</h5>
                          <form action="#">
                            <input
                              type="text"
                              name="name"
                              placeholder="Your name"
                            />
                            <input
                              type="email"
                              name="email"
                              placeholder="Email here"
                            />
                            <textarea></textarea>
                            <button type="button" className="submit-btn-1">
                              SUBMIT REVIEW
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="widget widget-featured-property mb-30">
                    <h5>Featured Property</h5>
                    <div className="row">
                      <div className="col-lg-12 col-md-6 col-12">
                        <div className="flat-item">
                          <div className="flat-item-image">
                            <span className="for-sale">For Sale</span>
                            <Link to="#">
                              <img src="/front/images/flat/1.jpg" alt="" />
                            </Link>
                            <div className="flat-link">
                              <Link to="#">More Details</Link>
                            </div>
                            <ul className="flat-desc">
                              <li>
                                <img src="/front/images/icons/4.png" alt="" />
                                <span>450 sqft</span>
                              </li>
                              <li>
                                <img src="/front/images/icons/5.png" alt="" />
                                <span>5</span>
                              </li>
                              <li>
                                <img src="/front/images/icons/6.png" alt="" />
                                <span>3</span>
                              </li>
                            </ul>
                          </div>
                          <div className="flat-item-info">
                            <div className="flat-title-price">
                              <h5>
                                <Link to="#">Masons de Villa </Link>
                              </h5>
                              <span className="price">$52,350</span>
                            </div>
                            <p>
                              <img
                                src="/front/images/icons/location.png"
                                alt=""
                              />
                              568 E 1st Ave, Ney Jersey
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
