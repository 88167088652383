import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PreLoader from "./PreLoader";
import { Link, useNavigate, createSearchParams  } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import LoadingBar from 'react-top-loading-bar'
Moment.globalFormat = 'D MMM YYYY hh:mm A';

export default function Home() {
  // Base Url
  const url = process.env.REACT_APP_BASE_URL;
  // Base Url
  const navigate  = useNavigate()
  const [landmark, setLandmark] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [type, setType] = useState("");
  
  const params = { landmark: landmark, min: min, max:max, type:type };

  const submitForm = () => {
    navigate({
      pathname: '/search',
      search: `?${createSearchParams(params)}`,
    });
  };

  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1169,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      }, // Medium Device
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
  var blog_carousel = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
  var testimonial_carousel = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var agents_carousel = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
  var service_carousel = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      }, // Tablet
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      }, // Large Mobile
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, // Small Mobile
    ],
  };
 
  // Setting API
  const [progress, setProgress] = useState(0)
  const [isLoading, setLoading] = useState(true);
  const [home, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/all_home_data`)
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);
  // Setting API
  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);
  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />
        <div className="slider-2 bg-3 bg-opacity-black-70" style={{ backgroundImage: `url(${url + home.home_page_image.image})` }}>
          <div
            className="wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className="find-home-box">
              <div className="section-title text-center text-white">
                <h2 className="h1 pb-2">{home.section_title.search_bar_title}</h2>
                <h6 className="text-white" style={{ fontWeight: "200" }}>{home.section_title.search_bar_sub_itle}</h6>
              </div>
              <div className="find-homes">
                <div className="row">
                  <div className="col-xl-4 col-lg-3 col-md-12 col-12">
                    <div className="find-home-item">
                      <input
                        type="text"
                        name="min-area"
                        placeholder="Enter Location, Landmark"
                        value={landmark}
                        onChange={(e) =>
                          setLandmark(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-12">
                    <div className="find-home-item">
                      <select
                        type="text"
                        name="min-area"
                        placeholder="Enter Location, Landmark"
                        onChange={(e) =>
                          setType(e.target.value)
                        }
                      >
                        <option>Property Type</option>
                        <option value="sale">Sale</option>
                        <option value="rent">Rent</option>
                        <option value="long lease">Long Lease</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-12">
                    <div className="find-home-item">
                      <input
                        type="text"
                        name="min-area"
                        placeholder="Enter Min Price"
                        value={min}
                        onChange={(e) =>
                          setMin(e.target.value)
                        }
                      />
                        
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-12">
                    <div className="find-home-item">
                      <input
                        type="text"
                        name="min-area"
                        placeholder="Enter Max Price"
                        value={max}
                        onChange={(e) =>
                          setMax(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-3 col-xs-12">
                    <div className="find-home-item">
                      <button className="button-1 btn-block btn-hover-1" onClick={submitForm}>
                        SEARCH
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="page-content" className="page-wrapper">
          <div className="about-sheltek-area ptb-115">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="section-title mb-30">
                    <h3>{ home.short_about_us.heading }</h3>
                    <h2>{ home.short_about_us.sub_heading }</h2>
                  </div>
                  <div className="about-sheltek-info">
                    <div
                          dangerouslySetInnerHTML={{
                            __html: home.short_about_us.content,
                          }}
                        />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="about-image">
                    <Link to="about.html">
                      <img src={url + home.short_about_us.image} alt={ home.short_about_us.heading } />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-area pb-60">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{home.section_title.our_service_title}</h2>
                    <p>{home.section_title.our_service_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Slider {...service_carousel}>
                    {home.all_sevice.map((sevice, i) => (
                      <div className="col" key={sevice.sevice_id+i}>
                        <div className="service-item">
                          <div className="service-item-image">
                            <Link to={"/services/" + sevice.service_slug}>
                              <img
                                src={url + sevice.service_image}
                                alt={sevice.service_title}
                              />
                            </Link>
                          </div>
                          <div className="service-item-info">
                            <h5>
                              <Link to={"/services/" + sevice.service_slug}>
                                {sevice.service_title}
                              </Link>
                            </h5>
                            <p>
                              {`${sevice.service_short_description.substring(
                                0,
                                100
                              )}...`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-area bg-1 call-to-bg plr-140 pt-75" style={{ backgroundImage: `url(${url + home.booking_section.bg_image})` }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>{home.booking_section.one_st_title}</h3>
                    <h2 className="h1">{home.booking_section.one_st_sub_title}</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="booking-conternt clearfix">
                    <div className="book-house text-white">
                      <h2>{home.booking_section.two_nd_title}</h2>
                      <h2 className="h5">{home.booking_section.two_nd_sub_title}</h2>
                    </div>
                    <div className="booking-imgae">
                      <img src={url + home.booking_section.image} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-flat-area pt-115 pb-80">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{home.section_title.property_title}</h2>
                    <p>{home.section_title.property_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="featured-flat">
                <div className="row">
                  {home.all_properties.map((properties, i) => (
                    <div className="col-lg-4 col-md-6 col-12" key={i}>
                      <div className="flat-item">
                        <div className="flat-item-image">
                          {properties.pr_sale_or_rent == 1 ?
                          <span className="for-sale">For Sale</span>
                          : properties.pr_sale_or_rent == 2 ?
                          <span className="for-sale rent">For Rent</span>
                          :
                          <span className="for-sale rent">For Long Lease</span>
                          }
                          <Link to={'/propertie-details/'+properties.pr_slug}>
                            <Carousel showArrows={false} showIndicators={false} swipeable={true} showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}>
                              {properties.image.map((all_img, i) => (
                                <div key={'123'+i}>
                                    <img src={url+all_img.pr_img} />
                                </div>
                              ))}
                            </Carousel>
                          </Link>
                          <div className="flat-link">
                            <Link to={'/propertie-details/'+properties.pr_slug}>More Details</Link>
                          </div>
                          <ul className="flat-desc">
                            {properties.area  ?
                              <li>
                                <img src="/front/images/icons/4.png" alt={properties.area} />
                                <span>{properties.area}</span>
                              </li>
                            :
                            <li></li>
                            }
                            {properties.bedroom > 0  ?
                              <li>
                                <img src="/front/images/icons/5.png" alt="Bedroom" />
                                <span>{properties.bedroom}</span>
                              </li>
                            :
                            <li></li>
                            }
                            {properties.bathroom > 0  ?
                              <li>
                                <img src="/front/images/icons/6.png" alt="BathRoom" />
                                <span>{properties.bathroom}</span>
                              </li>
                            :
                            <li></li>
                            }
                          </ul>
                        </div>
                        <div className="flat-item-info">
                          <div className="flat-title-price">
                            <h5>
                              <Link to={'/propertie-details/'+properties.pr_slug}>
                               {properties.pr_name}
                              </Link>
                            </h5>
                            <span className="price">{numberFormat(properties.price)}</span>
                          </div>
                          <p>
                            <img src="/front/images/icons/location.png" alt="" />
                            {properties.pr_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="features-area fix" style={{ backgroundImage: `url(${url + home.feature_info.image})` }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-7 offset-lg-5">
                  <div className="features-info bg-gray">
                    <div className="section-title mb-30">
                      <h3>{home.feature_info.title}</h3>
                      <h2 className="h1">{home.feature_info.sub_title}</h2>
                    </div>
                    <div className="features-desc">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: home.feature_info.content,
                          }}
                        />
                    </div>
                    <div className="features-include">
                      <div className="row">
                        {home.feature_contant.map((fea_content, i) => (
                          <div className="col-xl-4 col-lg-6 col-md-4" key={fea_content.id+i}>
                            <div className="features-include-list">
                              <h6>
                                <img src={url+fea_content.image} alt={fea_content.title} />
                                {fea_content.title}
                              </h6>
                              <p>{fea_content.description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-agents-area pt-115 pb-55">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{home.section_title.agent_title}</h2>
                    <p>{home.section_title.agent_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="our-agents">
                <div className="row">
                  <div className="col-12">
                    <Slider {...agents_carousel}>
                      {home.all_agent.map((agent, i) => (
                        <div className="col-12" key={agent.id+i}>
                          <div className="single-agent">
                            <div className="agent-image">
                              <img src={url+agent.image} alt={agent.name} />
                            </div>
                            <div className="agent-info">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/excutive-details/'+agent.slug}>
                                  {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                            </div>
                            <div className="agent-info-hover">
                              <div className="agent-name">
                                <h5>
                                  <Link to={'/excutive-details/'+agent.slug}>
                                    {agent.name}
                                  </Link>
                                </h5>
                                <p>{agent.position}</p>
                              </div>
                              <ul className="agent-address">
                                <li>
                                  <img
                                    src="/front/images/icons/phone-2.png"
                                    alt=""
                                  />{agent.mobile}
                                </li>
                                <li>
                                  <img
                                    src="/front/images/icons/mail-close.png"
                                    alt=""
                                  />{agent.email}
                                </li>
                              </ul>
                              <ul className="social-media">
                                <li>
                                  <a href={agent.fb_url}>
                                    <i
                                      className="fa fa-facebook"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.tw_url}>
                                    <i
                                      className="fa fa-twitter"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.linkdin_url}>
                                    <i
                                      className="fa fa-linkedin"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={agent.telegram_url}>
                                    <i
                                      className="fa fa-telegram"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-area pb-115">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="testimonial">
                    <div className="row">
                      <div className="col-lg-8 col-md-9">
                        <div className="section-title mb-30">
                          <h3>{home.all_testimonial.th_heading}</h3>
                          <h2 className="h1">{home.all_testimonial.th_sub_heading}</h2>
                        </div>
                        <Slider {...testimonial_carousel}>
                          {home.all_testimonial.testimonial.map((testi, i) => (
                            <div className="testimonial-item" key={'ssssss'+i}>
                              <div className="testimonial-brief">
                                <p>{testi.content}</p>
                              </div>
                              <h6>
                              {testi.name}, <span>{testi.position}</span>
                              </h6>
                            </div>
                          ))}
                        </Slider>
                      </div>
                      <div className="col-lg-4 col-md-3">
                        <div className="testimonial-image">
                          <img
                            src={url+home.all_testimonial.th_image}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="blog-area pb-60">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title-2 text-center">
                    <h2>{home.section_title.blog_title}</h2>
                    <p>{home.section_title.blog_sub_title}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Slider {...blog_carousel}>
                    {home.all_blog.map((blog, i) => (
                      <div className="col" key={blog.id+i}>
                        <article className="blog-item bg-gray">
                          <div className="blog-image">
                            <Link to={'/blog-details/'+blog.title_slug}>
                              <img src={url+blog.image} alt="" />
                            </Link>
                          </div>
                          <div className="blog-info">
                            <div className="post-title-time">
                              <h5>
                                <Link to={'/blog-details/'+blog.title_slug}>
                                {`${blog.title.substring(0, 30)}...`}
                                </Link>
                              </h5>
                              <p><Moment>{blog.date}</Moment></p>
                            </div>
                            <p>{`${blog.short_description.substring(0, 60)}...`}</p>
                            <Link className="read-more" to={'/blog-details/'+blog.title_slug}>
                              Read more
                            </Link>
                          </div>
                        </article>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="brand-area pb-115 d-none">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Slider {...settings}>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/1.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/2.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/3.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/4.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/5.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/1.png" alt="" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="brand-item">
                        <img src="/front/images/brand/4.png" alt="" />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
