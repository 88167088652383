import React, { useState, useEffect } from "react";
import PreLoader from "./PreLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import LoadingBar from 'react-top-loading-bar'
import Moment from 'react-moment';
Moment.globalFormat = 'D MMM YYYY hh:mm A';

export default function Properties() {
  const [query, setQuery] = useState("");
  const url = process.env.REACT_APP_BASE_URL;
  // Property API
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [property, setItemsHome] = useState([]);
  useEffect(() => {
    setProgress(progress + 10);
    window.scroll(0, 0);
    const getItemshome = async () => {
      setProgress(progress + 40);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/get_all_property`)
        .then(function (resultHome) {
          setProgress(80);
          setItemsHome(resultHome.data);
          setLoading(false);
          setProgress(100);
        })
        .catch(function (error) {
          setLoading(true);
        });
    };
    getItemshome();
  }, []);

  // Property API
  const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);
  if (isLoading) {
    return <PreLoader />;
  } else {
    return (
      <>
      <LoadingBar
        color='#f11946'
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />
        <div className="breadcrumbs-area bread-bg-1 bg-opacity-black-70">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumbs">
                  <h2 className="breadcrumbs-title">Properties</h2>
                  <ul className="breadcrumbs-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Properties</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="page-content" className="page-wrapper">
          <div className="featured-flat-area left-sidebar pt-115 pb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-lg-2">
                  <div className="row">
                    {property.filter(prop => {
                        if (query === '') {
                          return prop;
                        } else if (prop.pr_name.toLowerCase().includes(query.toLowerCase())) {
                          return prop;
                        }
                        else if (prop.area.toLowerCase().includes(query.toLowerCase())) {
                          return prop;
                        }
                        else if (prop.pr_address.toLowerCase().includes(query.toLowerCase())) {
                          return prop;
                        }
                        else if (prop.pr_amenities.toLowerCase().includes(query.toLowerCase())) {
                          return prop;
                        }
                        else if (prop.pr_condition.toLowerCase().includes(query.toLowerCase())) {
                          return prop;
                        }
                        else if (prop.pr_mode.toLowerCase().includes(query.toLowerCase())) {
                          return prop;
                        }
                        else if (prop.pr_avl_type.toLowerCase().includes(query.toLowerCase())) {
                          return prop;
                        }
                    }).map((properties, i) => (
                      <div className="col-md-6 col-12" key={'aaa'+i}>
                        <div className="flat-item">
                          <div className="flat-item-image">
                            {properties.pr_sale_or_rent == 1 ?
                            <span className="for-sale">For Sale</span>
                            : properties.pr_sale_or_rent == 2 ?
                            <span className="for-sale rent">For Rent</span>
                            :
                            <span className="for-sale rent">For Long Lease</span>
                            }
                            <Link to={'/propertie-details/'+properties.pr_slug}>
                              <Carousel showArrows={false} showIndicators={false} swipeable={true} showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}>
                                {properties.image.map((all_img, a) => (
                                  <div key={'123'+a}>
                                      <img src={url+all_img.pr_img} />
                                  </div>
                                ))}
                              </Carousel>
                            </Link>
                            <div className="flat-link">
                              <Link to={'/propertie-details/'+properties.pr_slug}>More Details</Link>
                            </div>
                            <ul className="flat-desc">
                              {properties.area  ?
                                <li>
                                  <img src="/front/images/icons/4.png" alt={properties.area} />
                                  <span>{properties.area}</span>
                                </li>
                              :
                              <li></li>
                              }
                              {properties.bedroom > 0  ?
                                <li>
                                  <img src="/front/images/icons/5.png" alt="Bedroom" />
                                  <span>{properties.bedroom}</span>
                                </li>
                              :
                              <li></li>
                              }
                              {properties.bathroom > 0  ?
                                <li>
                                  <img src="/front/images/icons/6.png" alt="BathRoom" />
                                  <span>{properties.bathroom}</span>
                                </li>
                              :
                              <li></li>
                              }
                            </ul>
                          </div>
                          <div className="flat-item-info">
                            <div className="flat-title-price">
                              <h5>
                                <Link to={'/propertie-details/'+properties.pr_slug}>
                                {properties.pr_name}
                                </Link>
                              </h5>
                              <span className="price">{numberFormat(properties.price)}</span>
                            </div>
                            <p>
                              <img src="/front/images/icons/location.png" alt="" />
                              {properties.pr_address}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-12 d-none">
                      <div className="pagination-area mb-60">
                        <ul className="pagination-list text-center">
                          <li>
                            <Link to="#">
                              <i
                                className="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">1</Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-lg-1">
                  <aside className="widget widget-search mb-30">
                    <form action="#">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search Property..."
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </form>
                  </aside>
                  <aside className="widget widget-categories mb-50">
                    <h5>Property Condition</h5>
                    <ul className="widget-categories-list">
                    <li onClick={(event) => setQuery('')}>
                        <a href="#">All</a>
                      </li>
                      <li onClick={(event) => setQuery('old')}>
                        <a href="#">Old</a>
                      </li>
                      <li onClick={(event) => setQuery('New-Ready to move')}>
                        <a href="#">New-Ready to move</a>
                      </li>
                      <li onClick={(event) => setQuery('New-Under construction')}>
                        <a href="#">New-Under construction </a>
                      </li>
                    </ul>
                  </aside>
                  <aside className="widget widget-categories mb-50">
                    <h5>Property Mode</h5>
                    <ul className="widget-categories-list">
                      <li onClick={(event) => setQuery('')}>
                        <a href="#">All</a>
                      </li>
                      <li onClick={(event) => setQuery('Residential Bungalow')}>
                        <a href="#">Residential Bungalow</a>
                      </li>
                      <li onClick={(event) => setQuery('Residential Apartment')}>
                        <a href="#">Residential Apartment</a>
                      </li>
                      <li onClick={(event) => setQuery('Commercial Office')}>
                        <a href="#">Commercial Office</a>
                      </li>
                      <li onClick={(event) => setQuery('Commercial Shop')}>
                        <a href="#">Commercial Shop</a>
                      </li>
                      <li onClick={(event) => setQuery('Agriculture Land')}>
                        <a href="#">Agriculture Land</a>
                      </li>
                      <li onClick={(event) => setQuery('Agriculture Plot')}>
                        <a href="#">Agriculture Plot</a>
                      </li>
                    </ul>
                  </aside>
                  <aside className="widget widget-categories mb-50">
                    <h5>Property Available</h5>
                    <ul className="widget-categories-list">
                      <li onClick={(event) => setQuery('')}>
                        <a href="#">All</a>
                      </li>
                      <li onClick={(event) => setQuery('Rent')}>
                        <a href="#">For Rent</a>
                      </li>
                      <li onClick={(event) => setQuery('Long Lease')}>
                        <a href="#">For Long Lease</a>
                      </li>
                      <li onClick={(event) => setQuery('Sale')}>
                        <a href="#">For Sale</a>
                      </li>
                    </ul>
                  </aside>
                  <aside className="widget widget-featured-property mb-30">
                    <h5>Featured Property</h5>
                    <div className="row">
                      <div className="col-lg-12 col-md-6 col-12">
                        <div className="flat-item">
                          <div className="flat-item-image">
                            <span className="for-sale">For Sale</span>
                            <Link to="#">
                              <img src="/front/images/flat/1.jpg" alt="" />
                            </Link>
                            <div className="flat-link">
                              <Link to="#">More Details</Link>
                            </div>
                            <ul className="flat-desc">
                              <li>
                                <img src="/front/images/icons/4.png" alt="" />
                                <span>450 sqft</span>
                              </li>
                              <li>
                                <img src="/front/images/icons/5.png" alt="" />
                                <span>5</span>
                              </li>
                              <li>
                                <img src="/front/images/icons/6.png" alt="" />
                                <span>3</span>
                              </li>
                            </ul>
                          </div>
                          <div className="flat-item-info">
                            <div className="flat-title-price">
                              <h5>
                                <Link to="#">Masons de Villa </Link>
                              </h5>
                              <span className="price">$52,350</span>
                            </div>
                            <p>
                              <img
                                src="/front/images/icons/location.png"
                                alt=""
                              />
                              568 E 1st Ave, Ney Jersey
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>

          <div className="subscribe-area bg-blue call-to-bg plr-140 ptb-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-12">
                  <div className="section-title text-white">
                    <h3>SUBSCRIBE</h3>
                    <h2 className="h1">NEWSLETTER</h2>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-12">
                  <div className="subscribe">
                    <form action="#">
                      <input
                        type="text"
                        name="subscribe"
                        placeholder="Enter yur email here..."
                      />
                      <button type="submit" value="send">
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
